import styles from "./styles/WebsiteScreenshots.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import useWindowDimensions from "../../hooks/useWindowDimensions";

type Props = {
  key: string;
  images: Array<string>;
  onChange?: (prevSlide: any, info: { currentSlide: any }) => void;
};

const WebsiteScreenshots = (props: Props) => {
  const { key, images = [], onChange } = props;

  const { width } = useWindowDimensions();

  const slides = 1;
  const slidesOffsetBefore = width > 800 ? (width - 800) / 2 : width * 0.075;

  return (
    <div className={styles.imagesContainer}>
      <Swiper
        onSlideChange={onChange}
        onSwiper={(swiper) => console.log(swiper)}
        slidesPerView={slides}
        className={styles.swiper}
        slidesOffsetBefore={slidesOffsetBefore}
      >
        {images.map((image, index) => (
          <SwiperSlide key={`${key}-${index}`}>
            <div>
              <img
                loading="lazy"
                src={image}
                className={styles.image}
                alt={`${key}-${index}`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default WebsiteScreenshots;
