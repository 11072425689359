import {
  FaAppStoreIos,
  FaChevronDown,
  FaChevronUp,
  FaDesktop,
  FaGooglePlay,
} from "react-icons/fa";
import useAppPage from "./hooks/useAppPage";
import PhoneScreenshots from "./phone-screenshots/PhoneScreenshots";
import styles from "./styles/AppPage.module.css";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import WebsiteScreenshots from "./website-screenshots/WebsiteScreenshots";

const options: { index: number; value: string; label: string }[] = [
  { index: 0, value: "stumblr", label: "Stumblr" },
  { index: 2, value: "mynexus", label: "My Nexus" },
  // { index: 1, value: "caffeind", label: "Caffeind" },
  { index: 1, value: "mpforaweek", label: "MP for a Week" },
];

const AppPage = () => {
  const { activeAppData, currentSlide, onChangeSlide, onChangeProject } =
    useAppPage();

  const {
    key,
    title,
    logo,
    active,
    links: { playStore, appStore, site },
    description,
    screenshots,
    type,
  } = activeAppData;

  return (
    <div>
      <div className={styles.header}>
        <h2 className={styles.sectionTitle}>Work</h2>
        <Dropdown
          options={options}
          onChange={onChangeProject}
          value={key}
          placeholder="Select an option"
          className={styles.dropdown}
          controlClassName={styles.dropdownControl}
          arrowClassName={styles.dropdownArrow}
          menuClassName={styles.dropdownMenu}
          arrowClosed={<FaChevronDown color={"#666666"} size={12} />}
          arrowOpen={<FaChevronUp color={"#666666"} size={12} />}
        />
      </div>
      <div className={styles.app}>
        {type === "app" && screenshots.length > 0 && (
          <PhoneScreenshots
            key={key}
            images={screenshots}
            onChange={onChangeSlide}
          />
        )}
        {type === "website" && screenshots.length > 0 && (
          <WebsiteScreenshots
            key={key}
            images={screenshots}
            onChange={onChangeSlide}
          />
        )}
        <div className={styles.descriptionContainer}>
          {title && (
            <div className={styles.titleRow}>
              <div className={styles.titleWithLogo}>
                {logo && (
                  <img src={logo} alt={"logo"} className={styles.logo} />
                )}
                <h3 className={styles.title}>{title}</h3>
              </div>
              <p>{`${currentSlide}/${screenshots.length}`}</p>
            </div>
          )}
          {!active && <p className={styles.comingSoon}>COMING SOON</p>}
          {description && <p className={styles.description}>{description}</p>}
        </div>
        {active && (
          <div className={styles.links}>
            {appStore && (
              <a href={appStore} target="_blank" rel="noreferrer">
                <FaAppStoreIos
                  color={"black"}
                  size={34}
                  className={styles.icon}
                />
              </a>
            )}
            {playStore && (
              <a href={playStore} target="_blank" rel="noreferrer">
                <FaGooglePlay
                  color={"black"}
                  size={34}
                  className={styles.icon}
                />
              </a>
            )}
            {site && (
              <a href={site} target="_blank" rel="noreferrer">
                <FaDesktop color={"black"} size={34} className={styles.icon} />
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppPage;
