import StumblrLogo from "../assets/stumblr-logo.png";
import StumblrLaunch from "../assets/screenshots/stumblrscreenshot-1.png";
import StumblrScreenshot2 from "../assets/screenshots/stumblrscreenshot-2-min.png";
import StumblrScreenshot3 from "../assets/screenshots/stumblrscreenshot-3-min.png";
import StumblrScreenshot4 from "../assets/screenshots/stumblrscreenshot-4-min.png";
import StumblrScreenshot5 from "../assets/screenshots/stumblrscreenshot-5-min.png";
import StumblrScreenshot6 from "../assets/screenshots/stumblrscreenshot-6-min.png";
import StumblrScreenshot7 from "../assets/screenshots/stumblrscreenshot-7-min.png";
import StumblrScreenshot8 from "../assets/screenshots/stumblrscreenshot-8-min.png";

import MyNexusLogo from "../assets/mynexus.png";
import MyNexusScreenshot1 from "../assets/screenshots/mynexus-1-min.png";
import MyNexusScreenshot2 from "../assets/screenshots/mynexus-2-min.png";
import MyNexusScreenshot3 from "../assets/screenshots/mynexus-3-min.png";
import MyNexusScreenshot4 from "../assets/screenshots/mynexus-4-min.png";
import MyNexusScreenshot5 from "../assets/screenshots/mynexus-5-min.png";
import MyNexusScreenshot6 from "../assets/screenshots/mynexus-6-min.png";

import MPForAWeekLogo from "../assets/mpforaweek.png";
import MPForAWeekScreenshot1 from "../assets/screenshots/mpforaweek-1-min.png";
import MPForAWeekScreenshot2 from "../assets/screenshots/mpforaweek-2-min.png";
import MPForAWeekScreenshot3 from "../assets/screenshots/mpforaweek-3-min.png";
import MPForAWeekScreenshot4 from "../assets/screenshots/mpforaweek-4-min.png";
import MPForAWeekScreenshot5 from "../assets/screenshots/mpforaweek-5-min.png";
import MPForAWeekScreenshot6 from "../assets/screenshots/mpforaweek-6-min.png";
import MPForAWeekScreenshot7 from "../assets/screenshots/mpforaweek-7-min.png";

import CaffeindLogo from "../assets/caffeind.png";
import CaffeindLaunch from "../assets/screenshots/caffeind_launch.png";

export const appData = {
  stumblr: {
    key: "stumblr",
    title: "Stumblr",
    logo: StumblrLogo,
    active: true,
    links: {
      appStore: "https://apps.apple.com/gb/app/stumblr/id1570342884",
      playStore:
        "https://play.google.com/store/apps/details?id=com.stumblr&gl=GB&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
    },
    description: `Stumblr is a pub crawl app that allows the user to filter their local area for pubs based on various features, be it dog-friendly, sunday roasts or pool tables, and tick them off as they go. 
    
    The user can create their own pub crawls or gain inspiration from others created by other users. Now available on the app store and the play store!`,
    screenshots: [
      StumblrLaunch,
      StumblrScreenshot2,
      StumblrScreenshot3,
      StumblrScreenshot4,
      StumblrScreenshot5,
      StumblrScreenshot6,
      StumblrScreenshot7,
      StumblrScreenshot8,
    ],
    type: "app",
  },
  tastd: {
    key: "tastd",
    title: "Tastd",
    logo: "",
    active: false,
    links: {
      appStore: "",
      playStore: "",
    },
    description: "",
    screenshots: [],
    type: "app",
  },
  mynexus: {
    key: "mynexus",
    title: "My Nexus Entrepeneur Skills Index",
    logo: MyNexusLogo,
    active: true,
    links: {
      appStore:
        "https://apps.apple.com/gb/app/entrepreneur-skills-index/id1512976589",
      playStore:
        "https://play.google.com/store/apps/details?id=com.mynexus.coffeeenterprise&hl=en_GB",
    },
    description: `My Nexus Entrepeneur Skills Index was designed and envisioned by myNexus Limited to help younger users to play an entrepeneurial game with the long term goal to link them with investors and other entrepeneurs. 
    
    The user must run a coffee shop and manage staff, stock and unexpected circumstances to increase revenue and profits, with a game algorithm created by myself and developed in collaboration for fishinabottle.`,
    screenshots: [
      MyNexusScreenshot1,
      MyNexusScreenshot2,
      MyNexusScreenshot3,
      MyNexusScreenshot4,
      MyNexusScreenshot5,
      MyNexusScreenshot6,
    ],
    type: "app",
  },
  mpforaweek: {
    key: "mpforaweek",
    title: "MP for a Week",
    logo: MPForAWeekLogo,
    active: true,
    links: {
      site: "https://learning.parliament.uk/en/resources/mp-for-a-week/",
    },

    description: `MP for a Week was developed by myself for parliament through fishinabottle, the game is a fully accessible game and was created to the highest of the WCAG standard. 
    
    The user takes the role of an MP participating in debates, press days and meetings to gain voter support and increase their media and party scores. mechanics and design are not mine.`,
    screenshots: [
      MPForAWeekScreenshot1,
      MPForAWeekScreenshot2,
      MPForAWeekScreenshot3,
      MPForAWeekScreenshot4,
      MPForAWeekScreenshot5,
      MPForAWeekScreenshot6,
      MPForAWeekScreenshot7,
    ],
    type: "website",
  },
  caffeind: {
    key: "caffeind",
    title: "Caffeind",
    logo: CaffeindLogo,
    active: true,
    links: {
      appStore: "",
      playStore: "",
    },
    description: "",
    screenshots: [CaffeindLaunch],
    type: "app",
  },
};
